.progress_container {
  z-index: 1000;
}

@media (min-height: 667px) {
  .progress_container {
    bottom: 50px;
    z-index: 1000;
  }
}

.progress_container > div {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress_container--wide {
  margin-bottom: 10px;
}

.progress_container--wide > div {
  justify-content: space-between;
  padding: 0 20px;
}

.progress_container button:disabled {
  opacity: 0.3;
}
.progress_container__count {
  font-size: 0.7rem;
  color: #f3f4f2;
}

.progress_container--fixed {
  bottom: 10px;
  left: 0;
  right: 0;
}
.progress_dots {
  padding: 0;
  padding-top: 1px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}

.progress_dots li {
  display: block;
  margin: 0 2px;
}

/*progress bar classes*/

.answer span {
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  transition: width 0.2s, background-color 0.2s;
}

@media (min-width: 360px) {
  .answer span {
    width: 6px;
    height: 6px;
  }
}

.answer--yes span {
  background-color: rgb(232, 196, 106);
}

.answer--no span {
  background-color: rgb(232, 111, 81);
}

.answer--active span {
  width: 18px;
  border-radius: 3px;
  background-color: white;
}

.explained_button {
  color: white;
  border: 0;
  margin: 0;
  background-color: transparent;
}

.explained_button:focus {
  outline: 0;
}
